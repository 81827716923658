import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-autobackup"
    }}>{`What is AutoBackup`}</h1>
    <p><strong parentName="p">{`AutoBackup - automated multi cloud backup solution.`}</strong></p>
    <p>{`AutoBackup is a data protection solution capable of performing automatic backups of resources according to the predefined policies.`}</p>
    <h2 {...{
      "id": "key-features"
    }}>{`Key features`}</h2>
    <h3 {...{
      "id": "resources-discovery"
    }}>{`Resources discovery`}</h3>
    <p>{`AutoBackup’s users don’t have to manually provide information about every single resource they want to backup. Instead, AutoBackup can scan their accounts and automatically detect which resources can be backed up.`}</p>
    <h3 {...{
      "id": "backup-events"
    }}>{`Backup events`}</h3>
    <p>{`Users can select Recovery Point Objective (RPO) for the resources they want to backup. Based on the selected RPO settings, AutoBackup periodically generates lists of backup events describing when particular resources will be backed up. Backup events are generated frequently enough to meet the RPO requirements.`}</p>
    <p>{`AutoBackup also allows to browse the backup events history, providing information about success rate value, trends and taken snapshots.`}</p>
    <h3 {...{
      "id": "snapshots-replication"
    }}>{`Snapshots replication`}</h3>
    <p>{`For most types of resources that can be backed up using AutoBackup, snapshots replication is supported. During the replication process snapshots are copied to the other region or account specified by a user.`}</p>
    <h3 {...{
      "id": "snapshots-retention"
    }}>{`Snapshots retention`}</h3>
    <p>{`AutoBackup can automatically remove obsolete snapshots. User can specify for how many days snapshots should be retained in accounts. There is also an option to specify a maximal number of snapshots to be retained instead of providing a number of days.`}</p>
    <h3 {...{
      "id": "notifications"
    }}>{`Notifications`}</h3>
    <p>{`AutoBackup sends notifications to inform about performed backup, replication and retention operations.`}</p>
    <p>{`User can receive notifications through the following notification channels:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`E-mail`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Slack`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PagerDuty.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "hooks"
    }}>{`Hooks`}</h3>
    <p>{`AutoBackup has pre- and post- hooks support to execute actions before and after doing a backup. This functionality can be used for example to stop the EC2 instance before taking a snapshot of an EBS volume.`}</p>
    <h3 {...{
      "id": "disaster-recovery-testing"
    }}>{`Disaster recovery testing`}</h3>
    <p>{`AutoBackup allows providing templates that can be used for performing tests to make sure that disaster recovery of resources can be done.
AutoBackup stores results of tests as logs that can be used for the auditory purposes in the future.`}</p>
    <h3 {...{
      "id": "supported-resource-types"
    }}>{`Supported resource types`}</h3>
    <p>{`This section provides a brief overview of resources that can be backed up by AutoBackup in supported cloud platforms.`}</p>
    <p><strong parentName="p">{`AWS:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`RDS instances`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`RDS clusters`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`EC2 instances`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DynamoDB tables`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`S3 buckets - only enabling/disabling cross-region or same-region replication (objects present in the backed up bucket before enabling backup are not copied to the bucket used for storing objects replicas).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Redshift clusters (without replication)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`EFS`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Route53`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Azure:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Azure VM`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Azure SAP HANA`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`GCP:`}</strong></p>
    <ul>
      <li parentName="ul">{`GCP VM`}</li>
    </ul>
    <p><strong parentName="p">{`IBM Cloud:`}</strong></p>
    <ul>
      <li parentName="ul">{`IBM Cloud VM`}</li>
    </ul>
    <h2 {...{
      "id": "key-concepts"
    }}>{`Key concepts`}</h2>
    <h3 {...{
      "id": "protected-account"
    }}>{`Protected account`}</h3>
    <p>{`Protected account is a cloud account which contains resources to protect. For protected account, you can select which resource types should be under Autobackup protection.`}</p>
    <h3 {...{
      "id": "vault-account"
    }}>{`Vault account`}</h3>
    <p>{`Vault account is used only for storing backup replicas. Resources contained within your vault accounts won’t be backed up.`}</p>
    <h3 {...{
      "id": "backup-event"
    }}>{`Backup event`}</h3>
    <p>{`Backup event is an event which groups all resources belonging to the same `}<a parentName="p" {...{
        "href": "#backup-plan"
      }}>{`backup plan`}</a>{`. Backup event is described by an execution date, and a list of resources which will be backed up on the execution date. Executed events also provide information about success rates, snapshots taken and their replicas.`}</p>
    <h3 {...{
      "id": "backup-plan"
    }}>{`Backup plan`}</h3>
    <p>{`Backup plan is a combination of 2 entities: `}<a parentName="p" {...{
        "href": "#backup-policy"
      }}>{`backup policy`}</a>{` and `}<a parentName="p" {...{
        "href": "#backup-setup"
      }}>{`backup setup`}</a>{`. They determine how often backup events will be created (effectively: how often backup operations will be performed). All resources belonging to the same backup plan (in other words, having the same `}<a parentName="p" {...{
        "href": "#backup-policy"
      }}>{`backup policy`}</a>{` and `}<a parentName="p" {...{
        "href": "#backup-setup"
      }}>{`backup setup`}</a>{` assigned) will be backed up as a part of the same backup event.`}</p>
    <h3 {...{
      "id": "backup-policy"
    }}>{`Backup policy`}</h3>
    <p>{`Backup policy specifies the Recovery Point Objective (RPO) and retention settings. You can create your own backup policies as well as choose from existing ones and assign them to resources you want to backup.`}</p>
    <h3 {...{
      "id": "backup-setup"
    }}>{`Backup setup`}</h3>
    <p>{`Backup setup contains a set of settings specific to a backed up resource type. For each type of resource, the backup process may be performed in a different way and require different information to be executed. Those specific pieces of information are saved in the backup setup. You can freely create as many setups as you need and assign them to resources you want to backup.`}</p>
    <h2 {...{
      "id": "release-notes"
    }}>{`Release notes`}</h2>
    <p>{`To see AutoBackup release notes please visit this `}<a parentName="p" {...{
        "href": "https://autobackup.nordcloudapp.com/notes"
      }}>{`link`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      